




























import Vue from 'vue'
import * as echarts from 'echarts'
import cyFormat from '@/utils/cyFormat'
import EchartsDaily from './EchartsDaily'
import windElement from "@/utils/windElement";
import moment, { Moment } from 'moment'
export default Vue.extend({
  props: {
    data: { type: Object },
    isSkycon: { type: Boolean, default: false },
  },
  data() {
    return {
      daysData: [] as any[],
    }
  },
  watch: {
    data() {
      this.echarts()
    },
  },
  mounted() {
    this.echarts()
  },
  methods: {
    echarts() {
      const dom: any = document.getElementById('echartsDay')
      const myChart: any = echarts.init(dom) // 基于准备好的dom，初始化echarts实例
      window.onresize = myChart.resize;  // 图表宽度自适应

      const { temperature, precipitation, skycon_08h_20h, skycon, skycon_20h_32h, wind, air_quality } = this.data
      const daysData: any[] = []
      for (let i = 0; i < skycon_08h_20h.length; i++) {
        const item: any = skycon_08h_20h[i];

        const dateMoment = moment(item.date)
        //@ts-ignore
        const e: any = air_quality.aqi[i];
        const windItem: any = wind[i];
        const skyCon20Item = skycon_20h_32h[i]
        let getWeek = this.getWeek(dateMoment)
        if (i === 0) {
          getWeek = '今天'
        }
        //@ts-ignore
        daysData.push({
          week: getWeek,
          date: dateMoment.format("M") + "/" + dateMoment.format("D"),
          skycon08Text: cyFormat.getSkyconDesc(item.value),
          skyCon08ImgUrl: `/static/weatherIcon/${item.value}.png`,
          skycon20Text: cyFormat.getSkyconDesc(skyCon20Item.value),
          skyCon20ImgUrl: `/static/weatherIcon/${skyCon20Item.value}.png`,
          aqi: this.getAqiDesc(e.max.chn),
          windDirection: windElement.windDirection(windItem.max.direction) + "风 ",
          windSpeed: windElement.windLevel(Math.round(windItem.max.speed * 10) / 10) + "级"
        })
      }
      this.daysData = daysData
      myChart.setOption(EchartsDaily(temperature, precipitation, this.isSkycon ? skycon : null))
    },
    getWeek(date: any) { // 时间戳
      const week = date.day();

      switch (week) {
        case 1:
          return '周一';
        case 2:
          return '周二';
        case 3:
          return '周三';
        case 4:
          return '周四';
        case 5:
          return '周五';
        case 6:
          return '周六';
        case 0:
          return '周日';
      }
    },
    getAqiDesc(value: number) {
      if (value <= 50) {
        return {
          aqi: value,
          desc: '优',
          background: '#00FF00'
        }
      } else if (value > 50 && value <= 100) {
        return {
          aqi: value,
          desc: '良',
          background: '#ffd400'
        }
      } else if (value > 100 && value <= 150) {
        return {
          aqi: value,
          desc: '轻度',
          background: '#f47920'
        }
      } else if (value > 151 && value <= 200) {
        return {
          aqi: value,
          desc: '中度',
          background: '#FF0000'
        }
      } else if (value > 200 && value <= 300) {
        return {
          aqi: value,
          desc: '重度',
          background: '#8A2BE2'
        }
      }
      else if (value > 300) {
        return {
          aqi: value,
          desc: '严重',
          background: '#C32148'
        }
      }

      return value;
    }


  }
})
