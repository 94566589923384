import moment from "moment";
import cyFormat from "@/utils/cyFormat";
const weatherIconSize = 24;
function renderWeather(param: any, api: any) {
  const point = api.coord([param.dataIndexInside, 0]);
  return {
    type: "image",
    style: {
      image: `/static/images/weatherIcon/${cyFormat.getSkyconIconByIndex(
        api.value(0)
      )}.png`,
      x: -weatherIconSize / 2,
      y: -weatherIconSize / 2,
      width: weatherIconSize,
      height: weatherIconSize,
    },
    position: [point[0], 18],
  };
}
const getOption = (data1: number[], data2: number[], data3: number[]): any => {
  const x: string[] = [];
  const y1: any[] = [];
  const y2: any[] = [];
  const ymax: any[] = [];
  const ymin: any[] = [];
  for (let i = 0; i < data1.length; i++) {
    const e: any = data1[i];
    x.push(moment(e.date).format("D") + "日");
    y1.push(Math.round(e.avg * 10) / 10);
    ymax.push(Math.round(e.max * 10) / 10);
    ymin.push(Math.round(e.min * 10) / 10);
  }
  for (let i = 1; i < data2.length; i++) {
    const e: any = data2[i];
    y2.push(Math.round(e.max * 10) / 10);
  }
  const fontSize = 14;
  const params: any = {
    animation: false,
    grid: {
      left: 0,
      right: 0,
      top: 15,
      bottom: 10,
    },
    xAxis: {
      show: false,
      type: "category",
      data: x,
      axisLine: {
        lineStyle: {
          color: "#FFFFFF",
        },
      },
    },
    yAxis: [
      {
        show: false,
        name: "温度/℃",
        type: "value",
        min: (value: any) => Math.round(value.min - 5),
        max: (value: any) => Math.round(value.max + 4),
        // splitNumber: 1,
        splitLine: { show: false },
        axisLine: {
          show: true,
          lineStyle: {
            color: "#FFFFFF",
          },
        },
      },
      {
        show: false,
        name: "降水/mm",
        type: "value",
        min: "dataMin",
        max: "dataMax",
        splitNumber: 1,
        splitLine: { show: false },
        axisLine: {
          show: true,
          lineStyle: {
            color: "#FFFFFF",
          },
        },
      },
      {
        axisLine: { show: false },
        axisTick: { show: false },
        axisLabel: { show: false },
        splitLine: { show: false },
      },
    ],
    series: [
      {
        data: ymax,
        symbol: "circle",
        // symbol: 'emptyCircle',
        symbolSize: 5,
        // areaStyle: {
        //   color: "rgba(255, 178, 12,0.1)",
        // },
        smooth: true,
        label: {
          show: true,
          color: "#FFB20C",
          fontSize,
          formatter: "{c0}℃",
        },
        itemStyle: {
          color: "#FFB20C",
        },
        type: "line",
        yAxisIndex: 0,
      },
      {
        data: ymin,
        symbol: "circle",
        symbolSize: 5,
        // areaStyle: {
        //   color: "rgba(52, 127, 247,0.1)",
        // },
        smooth: true,
        label: {
          show: true,
          color: "#347FF7",
          fontSize,
          position: "bottom",
          formatter: "{c0}℃",
        },
        itemStyle: {
          color: "#347FF7",
        },
        type: "line",
        yAxisIndex: 0,
        // }, {
        //     data: y2,
        //     itemStyle: {
        //         color: '#1286FC',
        //     },
        //     type: 'bar',
        //     barWidth: '50%',
        //     yAxisIndex: 1,
      },
    ],
  };
  // if(data3 && data3.length > 0) {
  //     const y3: any[][] = []
  //     for (let i = 1; i < data3.length; i++) {
  //         const e: any = data3[i]
  //         y3.push([cyFormat.getSkyconIndex(e.value)])
  //     }
  //     params.series.push({
  //         type: 'custom',
  //         renderItem: renderWeather,
  //         data: y3,
  //         yAxisIndex: 2,
  //         z: 11
  //     })
  // }
  return params;
};
export default getOption;
