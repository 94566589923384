









































































import Vue from 'vue'
import moment from 'moment'
import request from '@/utils/request'
import config from '@/utils/config';
export default Vue.extend({
  props: ['type'],
  data() {
    return {
      active: 0,
      nongshiData: [],
      growthData: [],
      pestsData: [],
      foodstuffData: [],
    }
  },
  mounted() {
    this.getData()
    this.getFoodstuffData()
  },
  methods: {
    getData() {
      // request.get('/api/tobacco/rest_disease_pests/current_disease_pests')
      // .then((res: any) => this.data = res)

      Promise.all([
        request.get('/api/respond/rest_pm/newest')
          .then((res: any) => {
            // console.log(res)
            if (res) {
              this.nongshiData.push(res)
            }
          }).catch(() => {
            // 接口有返回后这条数据可删除
            this.nongshiData = [{
              addTime: 1682413627033,
              attachmentJson: "[]",
              content: "近期部分茶区出现靶斑病，26日起气温明显回升，最高温在22-26度，雨后转晴，田间湿度大，有利于靶斑病爆发。建议：大田期以预防为主，打顶前15天，均匀喷施80%波尔多液可湿性粉剂600~750倍液，也可自行配制，或用80%代森锌可湿性粉剂500倍或58%甲霜·锰锌可湿性粉剂500倍兑水喷雾防治。初现病斑时可用10%井冈霉素水剂600倍液或8%井岗霉素水剂60ml/亩-120ml/亩或3%多抗霉素150倍液喷雾防治。及时清除无效底脚叶，带出茶田统一处理。",
              id: "d0b556f6e6254ca3bd1537dff82414ca",
              state: 1,
              tenantArea: config.defaultStationObj.adminCode,
              tenantId: "1",
              title: "2023年04月25日17时发布",
              type: 2,
              updateTime: 1682413867246,
            }]
          }),
        request.get('/api/tobacco/rest_growth_system/current_growth')
          .then((res: any) => {
            // console.log(res)
            if (res && res.length > 0) {
              res.map((e) => {
                e.startDate = String(e.startDate).length === 3 ? '0' + e.startDate : e.startDate
                e.endDate = String(e.endDate).length === 3 ? '0' + e.endDate : e.endDate
              })
              this.growthData = res
            }
          }),
        request.get('/api/tobacco/rest_disease_pests/current_disease_pests')
          .then((res: any) => {
            // console.log(res)
            if (res && res.length > 0) {
              res.map((e) => {
                e.startDate = String(e.startDate).length === 3 ? '0' + e.startDate : e.startDate
                e.endDate = String(e.endDate).length === 3 ? '0' + e.endDate : e.endDate
              })
              this.pestsData = res
            }
          })
      ])
    },
    getFoodstuffData() {
      this.foodstuffData = [{
        name: '早稻',
        data: [{
          order: '秧苗期',
          time: '秧苗期',
          target: '二化螟、稻飞虱、稻蓟马、稻瘟病',
          plan: '甲维盐+吡蚜酮+三环唑+芸苔素+磷酸二氢钾喷施送嫁药',
          remarks: '播种前用咪鲜胺、氰烯菌酯、甲霜.种菌唑浸种，噻虫嗪、吡虫啉拌种防种传病虫害',
        }, {
          order: '分蘖期第一次防治',
          time: '5月上、中旬',
          target: '二化螟、稻飞虱、稻瘟病',
          plan: '金龟子绿僵菌或球孢白僵菌或苏云金杆菌+枯草芽孢杆菌+5%阿维菌素100毫升+5%氯虫苯甲酰胺60毫升+10%吡虫啉20克+75%三环唑40克',
          remarks: '',
        }, {
          order: '破口前第二次防治（早、中熟品种）',
          time: '6月上、中旬',
          target: '二化螟、卷叶螟、稻飞虱、纹枯病、稻瘟病',
          plan: '井冈.腊芽菌+枯草芽孢杆菌+阿维菌素+虱螨脲+吡蚜酮+苯甲.嘧菌酯+芸苔素+磷酸二氢钾',
          remarks: '',
        }, {
          order: '破口前第二次（迟熟品种）',
          time: '6月下旬至7月上旬',
          target: '二化螟、卷叶螟、稻飞虱、纹枯病、稻瘟病',
          plan: '井冈.腊芽菌+枯草芽孢杆菌+阿维菌素+虱螨脲+吡蚜酮+苯甲.嘧菌酯+芸苔素+磷酸二氢钾',
          remarks: '',
        }]
      }, {
        name: '中稻',
        data: [{
          order: '秧苗期',
          time: '移栽前3天',
          target: '稻飞虱、螟虫、稻蓟马、稻瘟病',
          plan: '甲维.虱螨脲+吡蚜酮+三环唑+芸苔素+磷酸二氢钾+飞防助剂喷施送嫁药',
          remarks: '播种前用甲霜.种菌唑+噻虫胺拌种防种传病虫害',
        }, {
          order: '分蘖期第一次防治',
          time: '6月中旬',
          target: '二化螟、卷叶螟、稻飞虱、稻瘟病',
          plan: '金龟子绿僵菌或球孢白僵菌或苏云金杆菌+枯草芽孢杆菌+阿维菌素+阿维.茚虫威+苯甲.丙环唑+芸苔素+磷酸二氢钾+飞防助剂',
          remarks: '多效唑或者烯效唑压苗',
        }, {
          order: '破口期第二次防治',
          time: '7月中旬',
          target: '二化螟、卷叶螟、稻飞虱、纹枯病、稻曲病',
          plan: '金龟子绿僵菌或球孢白僵菌或苏云金杆菌+井冈.腊芽菌+虫螨腈.氯虫苯甲酰胺+烯啶.呋虫胺+吡唑.咪鲜胺+芸苔素+磷酸二氢钾+飞防助剂',
          remarks: '',
        }, {
          order: '齐穗至灌浆期第三次防治',
          time: '8月中旬',
          target: '二化螟、卷叶螟、稻飞虱、纹枯病、稻曲病',
          plan: '甲维.虱螨脲+噻嗪.噻虫胺+苯甲.吡唑+飞防助剂',
          remarks: '',
        }]
      }, {
        name: '晚稻',
        data: [{
          order: '秧苗期',
          time: '移栽前3天',
          target: '稻飞虱、螟虫、稻蓟马、稻瘟疸、南方黑条矮缩病',
          plan: '吡虫啉+毒氟磷或香菇多糖或超敏蛋白+金龟子绿僵菌或者球孢白僵菌+三环唑+芸苔素+磷酸二氢钾+飞防助剂',
          remarks: '播种前用咪鲜胺、氰烯菌酯、甲霜.种菌唑浸种，噻虫嗪、吡虫啉拌种防种传病虫害',
        }, {
          order: '一次防治分蘖期第',
          time: '8月初',
          target: '重防第三代二化螟，兼防卷叶螟、稻飞虱、稻瘟病',
          plan: '金龟子绿僵菌或球孢白僵菌+苏云金杆菌+井冈.腊芽菌+枯草芽孢杆菌+三氟本嘧啶+毒氟磷或香菇多糖或超敏蛋白+宁南霉素+苯甲.丙环唑+芸苔素+磷酸二氢钾+助剂',
          remarks: '多效唑或者烯效唑压苗',
        }, {
          order: '破口前第二次防治',
          time: '8月底至9月初',
          target: '重防第四代卷叶螟，兼防二化螟、稻飞虱，预防纹枯病、稻曲病',
          plan: '苏云金杆菌或短稳杆菌+三氟本嘧啶+毒氟磷或香菇多糖或超敏蛋白+宁南霉素+苯甲.丙环唑+芸苔素+磷酸二氢钾+助剂',
          remarks: '',
        }, {
          order: '齐穗至灌浆期第三次防治',
          time: '9月下旬',
          target: '重防稻飞虱，兼治二化螟、卷叶螟、纹枯病、稻曲病',
          plan: '氯氰.毒死蜱或者烯啶.呋虫胺+芸苔素+磷酸二氢钾+助剂',
          remarks: '',
        }]
      }]
    },
  },
  filters: {
    dtFormat(time: any) {
      return moment(time, 'MMDD').format('M月D日')
    }
  }
})
